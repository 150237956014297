<div class="wrapper-xl">
    <div class="row">
        <div class="col-sm-12 padder-lg">
            <h1 class="font-thin h3">Profile</h1>
            <div class="b-5x b-b b-black m-t-md m-b"></div>
            
            <div ng-include="'partials/form.html'"></div>

        </div>
    </div>
</div>
