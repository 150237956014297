<div data-ng-include="'blocks/conference_logo.html'"></div>

<div class="wrapper-lg">
    <div class="padder-lg">

        <div data-ng-include="'blocks/coi_review_conference_nav.html'"></div>

        <div ng-show="!review_faculty.length" class="alert alert-info">
            You do not have any faculty assigned for review.
        </div>

        <div ng-show="review_faculty.length">
            <uib-tabset class="tab-container" justified="false" active="true">
                <uib-tab index="'faculty_incomplete'" select="active_tab = 'faculty_incomplete'">
                    <uib-tab-heading>
                        Incomplete
                    </uib-tab-heading>
                    <div>
                        <div class="panel panel-default m-n table-responsive">
                            <table class="table table-hover table-condensed">
                                <tr>
                                    <th>Faculty Name</th>
                                    <th>Review Status</th>
                                    <th>Assigned At</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                                <tr ng-repeat="faculty in review_faculty" ng-if="faculty.review_status != 'Resolved'">
                                    <td>{{faculty.full_name}}</td>
                                    <td>{{faculty.review_status}}</td>
                                    <td>{{formatMomentDate(faculty.assigned_at,'MM/DD/YYYY h:mm A')}}</td>
                                    <td>
                                        <span ng-show="faculty.slides_uploaded" class="font-bold text-success">Slides Uploaded</span>
                                        <span ng-show="!faculty.slides_uploaded" class="font-bold">No Slides</span>
                                    </td>
                                    <td nowrap="">
                                        <button type="button" class="btn btn-default btn-xs" ng-click="loadSlideReviewFacultyModal(faculty.id)">Review</button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </uib-tab>
                <uib-tab index="'faculty_complete'" select="active_tab = 'faculty_complete'">
                    <uib-tab-heading>
                        Complete
                    </uib-tab-heading>
                    <div>
                        <div class="panel panel-default m-n table-responsive">
                            <table class="table table-hover table-condensed">
                                <tr>
                                    <th>Faculty Name</th>
                                    <th>Review Status</th>
                                    <th>Assigned At</th>
                                    <th>Completed At</th>
                                    <th></th>
                                </tr>
                                <tr ng-repeat="faculty in review_faculty" ng-if="faculty.review_status == 'Resolved'">
                                    <td>{{faculty.full_name}}</td>
                                    <td>{{faculty.review_status}}</td>
                                    <td>{{formatMomentDate(faculty.assigned_at,'MM/DD/YYYY h:mm A')}}</td>
                                    <td>{{formatMomentDate(faculty.completed_at,'MM/DD/YYYY h:mm A')}}</td>
                                    <td nowrap="">
                                        <button type="button" class="btn btn-default btn-xs" ng-click="loadSlideReviewFacultyModal(faculty.id)">View</button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </uib-tab>
            </uib-tabset>
        </div>

    </div>
</div>