<div data-ng-include="'blocks/conference_logo.html'"></div>

<div class="wrapper-lg">
    <div class="padder-lg">

        <div data-ng-include="'blocks/coi_review_conference_nav.html'"></div>

        <div ng-show="coi_conference" ng-hide="response_submitted">
                <div class="m-t-lg">
                    Do you accept to be a conflict of interest reviewer for {{conference.title}}?
                </div>

                <div class="text-center m-b-lg">

                    <div class="btn-group m-t-xs"
                         ng-init="vm.form[form_config.type].field_model[field.name] = (field.value != null) ? field.value : field.default"
                    >
                        <label ng-class="{ 'btn-default' : availability != 'Yes', 'btn-success active' : availability == 'Yes' }"
                               class="btn btn-xs btn-default"
                               ng-model="availability"
                               uib-btn-radio="'Yes'"
                               uncheckable
                        ><i class="fa fa-check text-active"></i> <span>Yes</span></label>

                        <label ng-class="{ 'btn-default' : availability != 'No', 'btn-danger active' : availability == 'No' }"
                               class="btn btn-xs btn-default"
                               ng-model="availability"
                               uib-btn-radio="'No'"
                               uncheckable
                        ><i class="fa fa-check text-active"></i> <span>No</span></label>

                    </div>

                </div>

                <div class="line line-dashed b-b b-primary2"></div>

                <div class="m-t-xl text-danger" ng-show="error_msg">
                    <span class="font-bold">Please fill-out the following required fields:</span>
                    <div class="line line-dashed b-b b-danger"></div>
                    <span class="font-bold" ng-bind-html="error_msg | unsafe"></span>
                    <div class="line line-dashed b-b b-danger"></div>
                </div>

                <div class="m-t-lg text-center">
                    <button ng-click="saveResponse()" type="button" class="btn btn-success">Save</span></button>
                </div>
        </div>

        <div ng-show="coi_conference.invite_status == 'Accepted' || (response_submitted && availability == 'Yes')">

            <div class="m-t-lg">
                You have accepted the invitation to review slides for {{conference.title}}.
            </div>

            <div class="line line-dashed b-b b-primary2"></div>

        </div>

        <div ng-show="coi_conference.invite_status == 'Declined' || (response_submitted && availability == 'No')">

            <div class="m-t-lg">
                You have declined the invitation to review slides for {{conference.title}}.
            </div>

            <div class="line line-dashed b-b b-primary2"></div>

        </div>

    </div>
</div>