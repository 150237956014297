<p><strong>Important:</strong> As a condition of in-person attendance, CRF mandates that all participants be fully
    vaccinated against COVID-19 at least two weeks before the date on which you will first attend the meeting.</p>

<table class="table borderless">
    <tr>
        <td>
            <label class="i-checks i-checks-sm"><input
                    type="radio"
                    name="meeting_availability"
                    ng-model="$parent.$parent.availability"
                    value="Yes"
                    xxxng-disabled="conference_faculty.availability == 'Yes' || conference_faculty.availability == 'No'"
            ><i></i></label>

        </td>
        <td>
            <p class="h4">I will attend in-person as faculty and I have/will be *fully vaccinated against COVID-19
                by the start of {{conference.title}} on
                {{formatMomentDate(conference.datetime_start, 'dddd, MMMM Do YYYY')}}
            </p>
            <p><em>*Fully vaccinated means having received the total number of doses recommended for the particular type
                of vaccine documented on a COVID-19 vaccination record card.<br>
                **Note: If your status regarding in-person vs virtual participation should change, please let us know as
                soon as possible by revisiting this page.</em>
            </p>

        </td>
    </tr>
    <tr>
        <td>
            <label class="i-checks i-checks-sm"><input
                    type="radio"
                    name="meeting_availability"
                    ng-model="$parent.$parent.availability"
                    value="Virtual"
                    xxxng-disabled="conference_faculty.availability == 'Yes' || conference_faculty.availability == 'No'"
            ><i></i></label>
        </td>
        <td>
            <p class="h4">I will participate virtually as faculty</p>
        </td>
    </tr>
    <tr>
        <td colspan="2">
            <div class="line line-dashed b-b b-primary2"></div>
        </td>
    </tr>
    <tr>
        <td>
            <label class="i-checks i-checks-sm"><input
                    type="radio"
                    name="meeting_availability"
                    ng-model="$parent.$parent.availability"
                    value="No"
                    xxxng-disabled="conference_faculty.availability == 'Yes' || conference_faculty.availability == 'No'"
            ><i></i></label>
        </td>
        <td>
            <p class="h4">I will <strong>not</strong> participate as faculty</p>
        </td>
    </tr>

</table>