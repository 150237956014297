<div class="wrapper-xl">
    <div class="row">
        <div class="col-sm-12 padder-lg">
            <h1 class="font-thin h3">Conflict Review: Recent and Upcoming Conferences</h1>
            <div class="b-5x b-b b-black m-t-md m-b"></div>

            <div ng-repeat="conference in conferences">
                <h4 class="font-thin">
                    <a ui-sref="app.coi_review({'conference_id' : conference.id, 'faculty_id' : '0'})"
                       class="text-info">{{conference.title}}</a>
                </h4>
                {{formatMomentDate(conference.datetime_start, 'MMM Do YYYY')}} to
                {{formatMomentDate(conference.datetime_end, 'MMM Do YYYY')}} | {{conference.venue.name}}
                <div class="line line-dashed line-lg b-b b-black" ng-show="!$last"></div>
            </div>

            <div class="line b-b b-black line-lg"></div>
        </div>
    </div>
</div>
