<div data-ng-include="'blocks/conference_logo.html'"></div>

<div class="wrapper-lg">
    <div class="padder-lg">

        <div data-ng-include="'blocks/conference_nav.html'"></div>

        <div class="m-t-lg">

            <p>
                <strong>Planners</strong> are responsible for assuring that the educational activity is educationally sound and free of commercial influence. Specific planner responsibilities include:
            </p>

            <ol>
                <li><strong><u>Disclosure Form:</u></strong>
                    Disclose financial relationships relative to the entire program, not just financial relationships relative to a particular session(s).
                    Planners of educational activities sponsored by the Cardiovascular Research Foundation (CRF) are required to disclose any real or apparent conflict of
                    interest; this includes any device, pharmaceutical, and/or digital health company. Planners not complying with this policy are not permitted to plan this activity.
                    <br><br></li>
                <li><strong><u>Planning Process:</u></strong> Participate in a minimum of one conference call or group email to discuss the program.<br><br></li>
                <li>
                    <strong><u>Guidelines:</u></strong> Ensure that the following guidelines are met:

                    <ul>
                        <li><strong>Industry employees</strong>
                            cannot participate in any accredited continuing education activity.
                            CRF defines an industry employee as an individual who either receives a salary or who has a management title/responsibility
                            irrespective of renumeration (e.g., Chief Medical Officer, member of the Board of Directors, etc) with a manufacturer, marketer, reseller, or
                            distributor of a healthcare product or service involved in the management of patients with any cardiovascular disease to include devices, drugs,
                            and digital health care products.
                        <li><strong>Companies</strong>
                            cannot identify programs, lecture topics, or individual faculty that they will support,
                            nor can companies be asked to suggest or recommend lecture topics or individual faculty.

                        <li>All recommendations for patient care in accredited continuing education must be based on current science, evidence, and
                            clinical reasoning, while giving a fair and balanced view of diagnostic and therapeutic options.</li>
                        <li>All scientific research referred to, reported, or used in accredited education in support or justification of a patient care
                            recommendation must conform to the generally accepted standards of experimental design, data collection, analysis, and interpretation. The program must be free from commercial influence and promote improvement in health care, not the proprietary interest of a company. Content cannot be included in accredited education if it advocates for unscientific approaches to diagnosis or therapy, or if the education promotes recommendations, treatment, or manners of practicing healthcare that are determined to have risks or dangers that outweigh the benefits or are known to be ineffective in the treatment of patients.</li>
                        <li>The agenda must be fair and balanced and not be about a single drug or device. Alternative drugs or devices must be included.</li>

                    </ul>
                    <br>
                </li>
                <li><strong><u>Evaluate Results:</u></strong> Review, analyze, and sign off on the CME evaluation results.</li>
            </ol>

        </div>

        <p>
            I agree to participate as planner at {{conference.title}} and acknowledge and accept the responsibilities to plan this accredited continuing education.
        </p>

        <div class="text-center m-b-lg">

            <div class="btn-group m-t-xs"
                 ng-init="vm.form[form_config.type].field_model[field.name] = (field.value != null) ? field.value : field.default"
            >
                <label ng-class="{ 'btn-default' : planner_availability != 'Yes', 'btn-success active' : planner_availability == 'Yes' }"
                       class="btn btn-xs btn-default"
                       ng-model="planner_availability"
                       uib-btn-radio="'Yes'"
                       uncheckable
                ><i class="fa fa-check text-active"></i> <span>Yes</span></label>

                <label ng-class="{ 'btn-default' : planner_availability != 'No', 'btn-danger active' : planner_availability == 'No' }"
                       class="btn btn-xs btn-default"
                       ng-model="planner_availability"
                       uib-btn-radio="'No'"
                       uncheckable
                ><i class="fa fa-check text-active"></i> <span>No</span></label>

            </div>

            <!--<label class="i-checks i-checks-sm"><input
                    type="radio"
                    name="meeting_planner_availability"
                    ng-model="planner_availability"
                    value="Yes"
                    xxxng-disabled="conference_faculty.planner_availability == 'Yes' || conference_faculty.planner_availability == 'No'"
            ><i></i></label> Yes

            <label class="i-checks i-checks-sm"><input
                    type="radio"
                    name="meeting_planner_availability"
                    ng-model="planner_availability"
                    value="No"
                    xxxng-disabled="conference_faculty.planner_availability == 'Yes' || conference_faculty.planner_availability == 'No'"
            ><i></i></label> No--->

        </div>

        <div class="line line-dashed b-b b-primary2"></div>

        <div class="m-t-xl text-danger" ng-show="error_msg">
            <span class="font-bold">Please fill-out the following required fields:</span>
            <div class="line line-dashed b-b b-danger"></div>
            <span class="font-bold" ng-bind-html="error_msg | unsafe"></span>
            <div class="line line-dashed b-b b-danger"></div>
        </div>

        <div class="m-t-lg text-center">
            <button ng-click="saveTask()" type="button" class="btn btn-success">Save and Continue</span></button>
            <button ng-click="cancelTask()" type="button" class="btn btn-danger m-l-sm ">Cancel</button>
        </div>

    </div>
</div>