<h1 class="font-thin h3 m-b-sm">{{conference.title}}</h1>
<div class="m-b-sm">
    <em class="h4">{{formatMomentDate(conference.datetime_start, 'MM/DD/YYYY')}} to {{formatMomentDate(conference.datetime_end, 'MM/DD/YYYY')}}, {{conference.venue.name}}</em>
</div>

<div class="b-5x b-b b-black m-t-md"></div>

<div class="m-t m-b-lg" ng-show="conf_menu == 'slide_response'">
    <span class="font-bold h5">Conflict Review: Invitation Response</span>
</div>

<div class="m-t m-b-lg" ng-show="conf_menu == 'coi_review'">
    <span class="font-bold h5">Conflict Review: Faculty with completed disclosures</span>
</div>