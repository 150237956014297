<div class="wrapper-lg">
    <div class="padder-lg">
        <div ng-controller="MaintenanceController">

            <div class="panel panel-default m-t-xl w-xxl" style="margin: 0 auto">
                <div class="panel-heading">
                    <span class="text-bold text-3x">Server Maintenance</span>
                </div>

                <div class="panel-body">

                    <div class="m-b text-2x text-bold">
                        Sorry! We are performing important server maintenance, during this time the website is unavailable.
                    </div>

                </div>

            </div>

            <div class="m-b-lg">

            </div>
            <div class="text-center">
                <p>
                    <small class="text-muted">&copy; 2019 Cardiovascular Research Foundation</small>
                </p>
            </div>
        </div>
    </div>
</div>