<div class="modal-header bg-black lter">

    <div class="row">
        <div class="col-xs-10 col-sm-10 col-md-11">
            <h5 class="modal-title text-white font-thin font-bold ">
                <span>{{faculty.full_name}}</span>
            </h5>
        </div>
        <div class="col-xs-2 col-sm-2 col-md-1 ">
            <button class="pull-right text-white modal-btn-hdr" ng-click="done()">
                <i class="glyphicon glyphicon-remove-circle"></i>
            </button>
        </div>
    </div>

</div>
<div class="modal-body no-padder bg-white-only">

    <div class="hbox hbox-auto-xs bg-light">
        <div class="col bg-white-only">
            <div class="vbox">
                <div class="row-row">
                    <div class="cell scrollable">
                        <div class="cell-inner">
                            <div class="wrapper">
                                <div class="row">
                                    <div class="col-md-12">

                                        <div ng-hide="faculty.review_status == 'Resolved'" class="alert alert-info">
                                            Please review the assignments below and determine if slide review is required. If required, click on the <span class="font-bold">Slide Review Required</span> column and select <span class="font-bold">'Yes'</span>.

                                            Click on <span class="font-bold">Add Comment</span> to communicate any comments or recommendations to the faculty.

                                            If no review is required proceed to step 3 and certify that all conflicts have been resolved.
                                        </div>

                                        <uib-accordion>
                                            <div uib-accordion-group class="panel-default" ng-init="open = true"
                                                 is-open="open">
                                                <uib-accordion-heading>
                                                    1. Assignments
                                                    <i class="pull-right glyphicon"
                                                       ng-class="open ? 'glyphicon-chevron-down':'glyphicon-chevron-right'"></i>
                                                </uib-accordion-heading>

                                                <div class="table-responsive">

                                                        <table class="table table-responsive table-condensed table-hover bg-white b-a m-b-none">
                                                            <tr>
                                                                <th>Day/Time</th>
                                                                <th colspan="2">Session</th>
                                                                <th>Title</th>
                                                                <th>Role</th>
                                                                <th>Slides</th>
                                                                <th>Slide Review Required?</th>
                                                                <th>Comments/Recommendations</th>
                                                            </tr>
                                                            <tr ng-repeat="assignment in faculty.assignments">
                                                                <td>
                                                                    {{formatMomentDate(assignment.datetime_start,'MM/DD/YYYY h:mm A')}}
                                                                </td>
                                                                <td>{{assignment.session_type}}</td>
                                                                <td>{{assignment.breadcrumb}}</td>
                                                                <td>{{assignment.title}}</td>
                                                                <td>{{assignment.moderator_role}}</td>
                                                                <td nowrap>
                                                                    <a ng-if="assignment.slide" class="btn btn-default btn-xs" href="{{assignment.slide}}" target="_blank">View Slides</a>
                                                                    <span ng-if="!assignment.slide">N/A</span>
                                                                </td>
                                                                <td>
                                                                    <p class="form-control-static"
                                                                       style="min-height: 20px; padding-top: 0px"
                                                                    >
                                                                        <a onbeforesave="updateSlideReviewRequired($data, assignment)"
                                                                           editable-radiolist="assignment.coi_slide_review_required"
                                                                           e-ng-options="v as v for v in ['Yes','No']"
                                                                           buttons="no"
                                                                           blur="submit"
                                                                           ng-if="faculty.review_status != 'Resolved'"
                                                                        >
                                                                            <span>
                                                                                {{assignment.coi_slide_review_required}}
                                                                            </span>
                                                                        </a>

                                                                        <span ng-if="faculty.review_status == 'Resolved'">
                                                                            {{assignment.coi_slide_review_required}}
                                                                        </span>
                                                                    </p>
                                                                </td>
                                                                <td ng-init="comments_collapse = true">
                                                                    <!-- show first entry only if multiple and collapsed -->
                                                                    <div ng-show="comments_collapse && assignment.notes.length">
                                                                        <span class="table-text-ellipsis">
                                                                            &bull; {{assignment.notes[0].note}}
                                                                        </span>
                                                                    </div>

                                                                    <!-- show all entries if multiple and not collapse -->
                                                                    <div ng-repeat="anote in assignment.notes"
                                                                         ng-show="!comments_collapse">
                                                                        &bull; {{anote.note}}
                                                                    </div>

                                                                    <div class="m-t-xs" ng-show="!comments_collapse && faculty.review_status != 'Resolved'">
                                                                        <!-- ADD COMMENTS FORM -->

                                                                        <form role="form" name="forms.comments">
                                                                            <textarea class="form-control" rows="3" placeholder="New Comment..." ng-model="new_comment[assignment.id]"></textarea>
                                                                        </form>
                                                                        <button ng-click="addAssignmentComment(assignment)" class="btn btn-xs btn-default m-t-xs"><i class="fa fa-fw fa-plus"></i> Add </button>
                                                                    </div>

                                                                    <a href ng-click="comments_collapse=!comments_collapse">
                                                                        <i ng-hide="faculty.review_status == 'Resolved'" ng-class="{ 'fa-caret-down' : comments_collapse, 'fa-caret-up' : !comments_collapse}" class="fa fa-fw fa-caret-down"></i>
                                                                        <span ng-show="comments_collapse" class="font-bold">
                                                                            <span ng-show="assignment.notes.length"><i class="fa fa-caret-down"></i> More</span>
                                                                            <span>  </span>
                                                                            <span ng-hide="faculty.review_status == 'Resolved'"><i class="glyphicon glyphicon-plus"></i> Add Comment</span>
                                                                        </span>
                                                                        <span ng-show="!comments_collapse" class="font-bold">
                                                                            <span ng-show="assignment.notes.length"><i class="fa fa-caret-up"></i> Less</span>
                                                                            <span>  </span>
                                                                            <span ng-if="faculty.review_status != 'Resolved'">Close</span>
                                                                        </span>
                                                                    </a>

                                                                </td>
                                                            </tr>

                                                        </table>

                                                    </div>

                                            </div>
                                        </uib-accordion>

                                        <uib-accordion>
                                            <div uib-accordion-group class="panel-default" ng-init="open = true"
                                                 is-open="open">
                                                <uib-accordion-heading>
                                                    2. Disclosures
                                                    <i class="pull-right glyphicon"
                                                       ng-class="open ? 'glyphicon-chevron-down':'glyphicon-chevron-right'"></i>
                                                </uib-accordion-heading>

                                                <div class="bg-light lter b">
                                                    <div class="wrapper-sm">
                                                        <div ng-include="'partials/disclosure_details.html'"></div>
                                                    </div>
                                                </div>

                                            </div>
                                        </uib-accordion>

                                        <div class="panel panel-default">
                                            <div class="panel-heading">
                                                <span class="h5">3. Finish Review</span>
                                            </div>
                                            <div class="panel-body text-center">


                                                <p ng-show="faculty.review_status == 'Resolved'" class="font-bold">
                                                    All conflicts have been resolved.
                                                </p>

                                                <div ng-hide="faculty.review_status == 'Resolved'">
                                                    <p>
                                                        <span class="font-bold">Before certifying that all conflicts have been resolved, you must do at least one of the following:</span>
                                                    </p>
                                                    <p>
                                                        (1) check the box indicating that no further action is required,<br>
                                                        (2) select at least one assignment above as requiring slide review,<br>
                                                        or<br>
                                                        (3) write in specific comments/recommendations
                                                    </p>

                                                    <p>
                                                        <span class="font-bold">To complete the review, you must certify below that all conflicts have been resolved</span>
                                                    </p>
                                                    <div class="checkbox">
                                                        <label class="i-checks i-checks-sm">
                                                            <input type="checkbox"
                                                                   name="conflicts_resolved"
                                                                   ng-model="conflicts_resolved"

                                                            > <i></i>
                                                            I certify that all conflicts have been resolved
                                                        </label>
                                                    </div>

                                                    <div class="text-center m-t-sm">
                                                        <button type="button" class="btn-success btn" ng-click="finishReview()">Save</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
