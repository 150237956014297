<div data-ng-include="'blocks/conference_logo.html'"></div>

<div class="wrapper-lg">
    <div class="padder-lg">

        <div data-ng-include="'blocks/conference_nav.html'"></div>

        <div ng-if="conference.type == 'Hybrid'"
             data-ng-include="'views/conferences/tasks_invitation_response_initial_hybrid.html'">
        </div>

        <div ng-if="conference.type != 'Hybrid'"
             data-ng-include="'views/conferences/tasks_invitation_response_initial_in_person.html'">
        </div>

        <div class="line line-dashed b-b b-primary2"></div>

        <div class="m-t-xl text-danger" ng-show="!replied">
            <span class="font-bold">
                <span ng-show="!availability">You must respond yes or no before proceeding.</span>
                <span ng-show="!availability_comments.text && availability">Please provide a reason for declining the invitation</span>
            </span>
            <div class="line line-dashed b-b b-danger"></div>
        </div>

        <div class="m-t-lg text-center">
            <!--<span ng-if="is_virtual">
                <button ng-disabled="!replied || !recording_days_replied || !recording_time_slots_replied"
                        ng-click="saveTask()" type="button" class="btn btn-success">Save <span
                        ng-show="task.status == '0'">and Continue</span></button>
            </span>
            <span ng-if="!is_virtual">
                <button ng-disabled="!replied"
                        ng-click="saveTask()" type="button" class="btn btn-success">Save <span
                        ng-show="task.status == '0'">and Continue</span></button>
            </span>--->
            <button ng-disabled="!replied"
                    ng-click="saveTask()" type="button" class="btn btn-success">Save <span
                    ng-show="task.status == '0'">and Continue</span></button>
            <button ng-click="cancelTask()" type="button" class="btn btn-danger m-l-sm ">Cancel</button>
        </div>

    </div>
</div>