<div class="wrapper-xl">
    <div class="row">
        <div class="col-sm-8 padder-lg">
            <h1 class="font-thin h3">Welcome, {{faculty.first_name}} {{faculty.middle_name}} {{faculty.last_name}}.</h1>
            <div class="b-5x b-b b-black m-t-md"></div>

            <div class="padder-v"><em class="font-bold">Please take a few minutes to complete your Outstanding Tasks.  All Tasks must be completed IN ORDER, to move on to the next.</em></div>

            <div class="m-t-sm" ng-repeat="conference in conferences">

                <div ng-show="conference.tasks.length && conference.faculty.availability != 'No'">
                    <div>
                        <h1 class="font-thin h4 text-danger">OUTSTANDING TASKS</h1>
                        <em class="h5">{{conference.title}} | {{conference.datetime_start | date : 'MMM d, y' : conference.venue.timezone }} - {{conference.datetime_end | date : 'MMM d, y' : conference.venue.timezone }} | {{conference.venue.name}} </em>
                    </div>

                    <div class="m-l-lg">
                        <div class="line b-danger b-b line-lg"></div>

                        <div ng-repeat="task in conference.tasks track by $index">
                            <div ng-switch="task.active">
                                <span ng-switch-when="1" class="font-bold"><a ng-click="redirectToTask(task.slug, task.id, conference.id)">{{task.name}}</a></span>
                                <span ng-switch-default class="text-muted">{{task.name}}</span>
                            </div>
                            <div class="line m-b-xs line-dashed b-b b-danger" ng-hide="$last"></div>
                        </div>

                        <div class="line b-danger b-b line-lg"></div>

                        <div class="text-right">
                            <span class="text-md"><em><a ui-sref="app.conference_tasks({'conference_id': conference.id})" class="text-info">View all {{conference.title}} tasks »</a></em></span>
                        </div>
                    </div>
                </div>

            </div>

        </div>
        <div class="col-sm-4 padder-lg">
            <h1 class="font-thin h3">Upcoming Conferences</h1>
            <div class="b-5x b-b b-black m-t-md m-b-sm"></div>

            <div ng-repeat="upcoming in conferences">
                <div ng-if="upcoming.faculty.availability != 'No'">
                    <div>
                        <h4 class="font-thin">
                            <a ui-sref="app.conference_tasks({'conference_id' : upcoming.id})" class="text-info">{{upcoming.title}}</a>
                        </h4>
                        {{upcoming.datetime_start | date : 'MMM d, y' : upcoming.venue.timezone }} - {{upcoming.datetime_end | date : 'MMM d, y' : upcoming.venue.timezone }}<br>
                        {{upcoming.venue.name}}
                    </div>

                    <div class="line line-dashed b-b b-black line-lg" ng-show="!$last"></div>
                </div>
            </div>


            <div class="line b-b b-black line-lg"></div>

            <div class="text-right">
                <span class="text-md"><em><a ui-sref="app.conferences" class="text-info">View all conferences »</a></em></span>
            </div>


        </div>
    </div>
</div>
