<div data-ng-include="'blocks/conference_logo.html'"></div>

<div class="wrapper-lg">
    <div class="padder-lg">

        <div data-ng-include="'blocks/conference_nav.html'"></div>

        <p class="font-bold">
            You must indicate your specialties below and if applicable, indicate the types of procedures you perform. <br>
            Click Save <span ng-show="task.status == '0'">and Continue</span> at the bottom of this page when you are done.
        </p>

       <!--<pre>{{selected_specialties | json}}</pre>--->

        <div class="line  line-double line-lg b-b b-t b-primary2"></div>

        <ul style="list-style-type: none;">
            <li ng-repeat="specialty in specialties" class="m-t">
                <label class="i-checks i-checks-sm font-bold">
                    <input type="checkbox" ng-model="selected_specialties[specialty.id].option" ng-true-value="'{{specialty.name}}'">
                    <i></i>
                    {{specialty.name}}
                </label>

                <!--<div style="margin-left: 27px;" ng-if="specialty.name == 'Other'">
                    <em><span class="small">Please list specialties separate by a comma</span></em><br>
                    <input ui-jq="tagsinput" ui-options="" class="form-control w-md" ng-model="selected_specialties[specialty.id].other_option" />
                </div>--->

                <div style="margin-left: 27px;" >
                    <em ng-if="specialty.children.length"><span class="small">Check all that apply (you must check at least 1 box below)</span></em>
                </div>

                <ul style="list-style-type: none;" ng-if="specialty.children.length" ng-init="selected_specialties[specialty.id].sub_exist = specialty.children.length">
                    <li ng-repeat="sub_specialty in specialty.children">
                        <label class="i-checks i-checks-sm">
                            <input type="checkbox" ng-click="setParent(specialty.id, sub_specialty.id, specialty.name)" ng-model="selected_specialties[specialty.id].sub_specialties[sub_specialty.id].option" ng-true-value="'{{sub_specialty.name}}'">
                            <i></i>
                            {{sub_specialty.name}}
                        </label>

                        <div style="margin-left: 27px;" ng-if="sub_specialty.name == 'Other'">
                            <div ng-show="selected_specialties[specialty.id].sub_specialties[sub_specialty.id].option">
                                <em><span class="small">Please list sub specialties separate by a comma</span></em><br>
                                <input ng-onchange="setSubParent(specialty.id, sub_specialty.id, sub_specialty.name)" ui-jq="tagsinput" ui-options="" class="form-control w-md" ng-model="selected_specialties[specialty.id].sub_specialties[sub_specialty.id].other_option" />
                            </div>
                        </div>

                    </li>
                </ul>
                <div class="line line-dashed b-b b-primary2"></div>
            </li>
        </ul>

        <div class="m-t-xl text-danger" ng-show="error_msg">
            <span class="font-bold">Please fill-out the following required fields:</span>
            <div class="line line-dashed b-b b-danger"></div>
            <span class="font-bold" ng-bind-html="error_msg | unsafe"></span>
            <div class="line line-dashed b-b b-danger"></div>
        </div>

        <div class="m-t-lg text-center">
            <button ng-click="saveTask()" type="button" class="btn btn-success">Save <span ng-show="task.status == '0'">and Continue</span></button>
            <button ng-click="cancelTask()" type="button" class="btn btn-danger m-l-sm ">Cancel</button>
        </div>

    </div>
</div>