<div data-ng-include="'blocks/conference_logo.html'"></div>

<div class="wrapper-lg">
    <div class="padder-lg">

        <div data-ng-include="'blocks/conference_nav.html'"></div>

        <p class="font-bold">
            So we may optimize your experience at {{conference.title}}, please fill out all of the following sections. Required items are markeded with an asterisk(*). You must click the Add button on the right side of the page after each entry in all sections below.
            Click the Save <span ng-show="task.status == '0'">and Continue</span> button at the bottom of this page when you are finished.
        </p>

        <!-- AREAS OF EXPERTISE -->
        <div class="m-t-lg">
            <div class="hbox">
                <div class="col">
                    <a ng-click="topics_collapse_state_state[topics] = !topics_collapse_state_state[topics];"><span class="h4 font-bold" ng-init="topics_collapse_state_state[topics] = false">I. AREAS OF EXPERTISE</span> <span><code>*</code></span></a>
                </div>
                <div class="col text-right">
                    <span>
                        <a ng-click="topics_collapse_state_state[topics] = !topics_collapse_state_state[topics];">
                            <i ng-class="{'fa-angle-right' : topics_collapse_state_state[topics], 'fa-angle-down' : !topics_collapse_state_state[topics]}" class="fa fa-fw fa-2x"></i>
                        </a>
                    </span>
                </div>
            </div>

            <div class="line line-double b-b b-t b-primary2"></div>
            <div uib-collapse="topics_collapse_state_state[topics]">
                <p class="font-bold">
                    To select Areas of Expertise, please chose a category from the drop down menu. Once you have selected your topic, click the “ADD” button to the right.  Please repeat this process until you have listed all your areas of expertise. <strong>Limit of (5) selections.</strong>
                </p>

                <div class="m-b" ng-show="faculty_topics.length < 5">
                    <div class="hbox">
                        <div class="col" style="width: 125px">
                            <ui-select ng-model="new_topic.selected" theme="bootstrap">
                                <ui-select-match placeholder="Select or type to search ...">{{$select.selected.name}}</ui-select-match>
                                <ui-select-choices repeat="option in topics | filter: $select.search">
                                    <div ng-bind-html="option.name | highlight: $select.search"></div>
                                </ui-select-choices>
                            </ui-select>
                        </div>

                        <div class="col" style="width: 275px" ng-show="new_topic.selected.name">
                            <div class="m-l">
                                <ui-select ng-model="new_sub_topic.selected" theme="bootstrap">
                                    <ui-select-match placeholder="Select or type to search ...">{{$select.selected.name}}</ui-select-match>
                                    <ui-select-choices repeat="option in sub_topics | filter: $select.search">
                                        <div ng-bind-html="option.name | highlight: $select.search"></div>
                                    </ui-select-choices>
                                </ui-select>
                            </div>
                        </div>

                        <div class="col" style="width: 350px" ng-show="new_sub_topic.selected.name">
                            <div class="m-l">
                                <ui-select ng-model="new_sub_sub_topic.selected" theme="bootstrap">
                                    <ui-select-match placeholder="Select or type to search ...">{{$select.selected.name}}</ui-select-match>
                                    <ui-select-choices repeat="option in sub_sub_topics | filter: $select.search">
                                        <div ng-bind-html="option.name | highlight: $select.search"></div>
                                    </ui-select-choices>
                                </ui-select>
                            </div>
                        </div>

                        <div class="col">
                            <div class="m-l">
                                <button ng-click="addTopic()" type="button" class="btn btn-sm btn-primary"><i class="fa fa-plus fa-fw"></i> Add</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="font-bold">Topics you have selected:</div>
                <div class="line line-dashed b-b b-primary2"></div>

                <div ng-show="faculty_topics.length == 0">None</div>

                <!-- SELECTED TOPICS -->
                <div ng-repeat="topic in faculty_topics track by $index">

                    <div class="hbox m-b">
                        <div class="col v-middle" style="width: 400px;">
                            <span>{{$index+1}}. {{topic.name}}</span>
                        </div>
                        <div class="col v-middle">
                            <div class="m-l">
                                <button ng-click="removeTopic(topic)" class="btn btn-xs btn-danger"><i class="fa fa-times text-white"></i> Delete</button>
                            </div>
                        </div>
                    </div>

                    <div class="line line-dashed b-b b-primary2"></div>
                </div>

            </div>
        </div>

        <div class="m-t-xl text-danger" ng-show="error_msg">
            <span class="font-bold">Please fill-out the following required fields:</span>
            <div class="line line-dashed b-b b-danger"></div>
            <span class="font-bold" ng-bind-html="error_msg | unsafe"></span>
            <div class="line line-dashed b-b b-danger"></div>
        </div>

        <div class="m-t-lg text-center">
            <button ng-click="saveTask()" type="button" class="btn btn-success">Save <span ng-show="task.status == '0'">and Continue</span></button>
            <button ng-click="cancelTask()" type="button" class="btn btn-danger m-l-sm ">Cancel</button>
        </div>

    </div>
</div>