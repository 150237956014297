<div class="wrapper-lg">
    <div class="padder-lg">

        <div>
            <h1 class="font-thin h3">{{year}} Annual Disclosure</h1>
            <div class="b-5x b-b b-black m-t-md m-b"></div>
        </div>

        <div ng-if="show_declaration" ng-include="'partials/disclosure_declaration.html'"></div>

    </div>
</div>


