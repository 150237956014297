<div class="wrapper-lg">
    <div class="padder-lg" >

        <div data-ng-include="'blocks/conference_nav.html'"></div>

        <div>
            <div class="line  line-double line-lg b-b b-t b-primary2"></div>

            <div class="row">
                <div class="col-sm-2 font-bold">Meeting Name:</div>
                <div class="col-sm-10">{{conference.title}}</div>
            </div>
            <div class="row">
                <div class="col-sm-2 font-bold">Meeting Location:</div>
                <div class="col-sm-10">{{conference.venue.name}}</div>
            </div>
            <div class="row">
                <div class="col-sm-2 font-bold">Date of Meeting:</div>
                <div class="col-sm-10">{{conference.datetime_start | date : 'MM/dd/yyyy' : conference.venue.timezone }} - {{conference.datetime_end | date : 'MM/dd/yyyy' : conference.venue.timezone }}</div>
            </div>
            <div class="row">
                <div class="col-sm-2 font-bold">Faculty Name:</div>
                <div class="col-sm-10">{{faculty.last_name}}, {{faculty.first_name}}</div>
            </div>

            <div class="line  line-double line-lg b-b b-t b-primary2"></div>
        </div>

        <div ng-if="show_declaration" ng-include="'partials/disclosure_declaration.html'"></div>

    </div>
</div>