<div data-ng-include="'blocks/conference_logo.html'"></div>

<div class="wrapper-lg">
    <div class="padder-lg">

        <div data-ng-include="'blocks/conference_nav.html'"></div>

        <p>
            The following lectures have been selected for slide review. Please click on the <span class="font-bold">Upload Slides</span> button to upload your slides.
        </p>

        <div class="table-responsive">
            <table class="table b-a bg-white m-b-none">
                <tr>
                    <th>Day/Time</th>
                    <th>Session</th>
                    <th>Title</th>
                    <th>Role</th>
                    <th>Slides</th>
                    <th>Comments/Recommendations</th>
                </tr>
                <tr ng-repeat="assignment in faculty_assignments" ng-if="assignment.coi_slide_review_required == 1">
                    <td>{{formatMomentDate(assignment.event.datetime_start,'MM/DD/YYYY h:mm A')}}</td>
                    <td>{{assignment.event.breadcrumb}}</td>
                    <td>{{assignment.event.title}}</td>
                    <td>{{assignment.moderator_role}}</td>
                    <td nowrap>
                        <div ng-hide="slide_uploader[assignment.id].queue.length == 1">

                            <a href="{{assignment.slide}}" class="btn btn-success btn-xs m-b-sm" ng-show="assignment.slide">View Current Slide File</a>

                            <input type="file"
                                   class="custom-file-input-single custom-file-input-single-slides"
                                   nv-file-select
                                   uploader="slide_uploader[assignment.id]"
                            />
                        </div>

                        <table class="table table-condensed" ng-show="slide_uploader[assignment.id].queue.length">
                            <thead>
                            <tr>
                                <th width="50%">Name</th>
                                <th ng-show="slide_uploader[assignment.id].isHTML5">Size</th>
                                <th ng-show="slide_uploader[assignment.id].isHTML5">Progress</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr ng-repeat="item in slide_uploader[assignment.id].queue">
                                <td>
                                    <strong>{{ item.file.name }}</strong>
                                </td>
                                <td ng-show="slide_uploader[assignment.id].isHTML5" nowrap>{{ item.file.size/1024/1024|number:2 }} MB</td>
                                <td ng-show="slide_uploader[assignment.id].isHTML5">
                                    <div class="progress progress-sm m-b-none m-t-xs">
                                        <div class="progress-bar bg-info" role="progressbar" ng-style="{ 'width': item.progress + '%' }"></div>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <span ng-show="!item.isSuccess && !item.isCancel && !item.isError" class="text-info"><i class="glyphicon glyphicon-upload"></i></span>
                                    <span ng-show="item.isSuccess" class="text-success"><i class="glyphicon glyphicon-ok"></i></span>
                                    <span ng-show="item.isCancel" class="text-warning"><i class="glyphicon glyphicon-ban-circle"></i></span>
                                    <span ng-show="item.isError" class="text-danger"><i class="glyphicon glyphicon-remove"></i></span>
                                </td>
                                <td nowrap>
                                    <button type="button" class="btn btn-default btn-xs" ng-click="item.upload()" ng-disabled="item.isReady || item.isUploading || item.isSuccess">
                                        Upload
                                    </button>
                                    <button type="button" class="btn btn-default btn-xs" ng-click="item.cancel()" ng-disabled="!item.isUploading">
                                        Cancel
                                    </button>
                                    <button type="button" class="btn btn-default btn-xs" ng-click="item.remove()">
                                        Remove
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <div class="m-b">
                            <div ng-show="slide_uploader[assignment.id].isUploading">
                                <p>Upload progress:</p>
                                <div class="progress bg-light dker" style="">
                                    <div class="progress-bar progress-bar-striped bg-info" role="progressbar" ng-style="{ 'width': slide_uploader[assignment.id].progress + '%' }"></div>
                                </div>
                            </div>

                            <!--<button type="button" class="btn btn-xs btn-success" ng-click="slide_uploader[assignment.id].uploadAll()" ng-show="slide_uploader[assignment.id].isUploading" ng-disabled="!slide_uploader[assignment.id].getNotUploadedItems().length">
                                <i class="fa fa-arrow-circle-o-up"></i> Upload all
                            </button>
                            <button type="button" class="btn btn-xs btn-warning" ng-click="slide_uploader[assignment.id].cancelAll()" ng-show="slide_uploader[assignment.id].isUploading">
                                <i class="fa fa-ban"></i> Cancel all
                            </button>
                            <button type="button" class="btn btn-xs btn-danger" ng-click="slide_uploader[assignment.id].clearQueue()" ng-show="slide_uploader[assignment.id].queue.length">
                                <i class="fa fa-trash-o"></i> Remove all
                            </button>-->
                        </div>

                    </td>
                    <td>
                        <ul class="list-group">
                            <li class="list-group-item" ng-repeat="note in assignment.notes">{{note.note}}</li>
                        </ul>
                    </td>
                </tr>

            </table>
        </div>

    </div>
</div>



