<div ng-controller="DisclosureDeclarationController">
    <!---<pre>{{disclosure_section}}</pre>--->

    <div ng-show="disclosure_section == 'faculty'">
        <p class="font-bold">To complete this page you must read the statements, answer the 3 sections below and check
            the certification boxes at the bottom of this page. Click Save <span ng-show="task.status == '0'">and Continue</span>
            when you are finished.</p>
        <p class="text-danger">NOTE: The disclosure fields below are linked directly in with the CONFERENCE APP. Please
            ensure your entries are accurate and up to date.</p>
    </div>

    <div ng-show="disclosure_section == 'global'">
        <p class="font-bold">To complete this page you must read the statements, answer the 3 sections below and check
            the certification boxes at the bottom of this page. Click Save when you are finished.</p>
        <p class="small">Persons not submitting an accurately completed faculty disclosure statement will not be
            permitted to participate as faculty. Please list all potential conflicts of interest which may be relevant
            to your participation in any educational activity, keeping in mind that your
            participation may involve general panel discussions or moderation of live cases, and/or case review
            workshops involving numerous drugs or devices.</p>
    </div>

    <div class="panel panel-default">
        <div class="panel-heading">
            <span class="h4">Disclosure Declaration</span>
            <span class="font-bold">(Scroll down and read to accept policy)</span>
        </div>
        <div class="panel-body" style="height: 300px; overflow-y: scroll" scrolly="setPolicyRead()">
            <p class="text-center font-bold m-b">
                CRF accredited CE Activity Disclosure of Financial Relationship and Acceptance of Responsibility
            </p>
            <p>Cardiovascular Research Foundation (CRF) is accredited by the Accreditation Council for Continuing Medical Education (ACCME) to provide continuing education for physicians. CRF designates educational activities for <em>AMA PRA Category 1 CreditTM</em>. As such, CRF has the obligation to ensure delivery of education that is accurate, balanced, free of bias, objective, and scientifically rigorous. </p>
            <p>It is mandatory that all planners, faculty, presenters, authors must complete this form, sign and return it. Participants who fail to promptly complete this form and return to CRF, or as directed, will be removed and replaced, in accordance with ACCME requirements.</p>
            <p>All the recommendations involving clinical medicine in an accredited continuing education (CE) activity are based on evidence that is accepted within the profession of medicine as adequate justification for their indications and contraindication in the care of patients. All scientific research referred to, reported or used in an accredited continuing education in support or justification of a patient care recommendation must conform to the generally accepted standards of experimental design, data collection and analysis. </p>
            <p>Learners participating in any accredited CE activity sponsored, co-sponsored, or jointly provided by CRF must be able to evaluate the objectivity of the information, analysis, and recommendations presented. Thus, <strong>all financial relationships&nbsp;of planners, faculty, presenters, authors and others in control of educational content must be&nbsp;</strong>disclosed to learners.</p>
            <p><strong><u>Ineligible company</u></strong><strong> is defined by the ACCME as companies that are ineligible to be accredited in the ACCME System whose primary business is producing, marketing, selling, re-selling, or distributing healthcare products used by or on patients. </strong></p>
            <p><u>Financial relationships</u> accredited providers must collect information from all planners, faculty, and others in control of educational content about all their financial relationships with ineligible companies within the prior 24 months. There is no minimum financial threshold; individuals must disclose all financial relationships, regardless of the amount, with ineligible companies. Individuals must disclose financial relationships with ineligible companies regardless of their view of the relevance of the relationship to the education.</p>
            <p><a style="text-decoration: underline;" target="_blank" href="https://www.accme.org/accreditation-rules/policies/financial-relationships-and-conflicts-interest">Financial relationships</a> relevant financial relationships are financial relationships of any amount with <br>
                ineligible companies if the educational content is related to the business lines or products of the ineligible company. Providers are required to collect information from all those individuals in control of educational content about all of their financial relationships <strong>with ineligible companies within the prior 24 months</strong>. CRF as the accredited provider is then responsible for determining which relationships are relevant.<br>
                <br>
                Based on the above, <strong><u>ALL</u></strong> planners, faculty, presenters, authors must: </p>
            <ol type="A">
                <li>Disclose information about all their financial relationships with ineligible companies over the previous 24 months <u>OR</u> indicate that they have nothing to disclose. </li>
                <li>Disclose the name of the ineligible company with which the person has a financial relationship.</li>
                <li>Disclose the nature of the financial relationship. Examples of financial relationships include employee, researcher, consultant, advisor, speaker, independent contractor (including contracted research), royalties or patent beneficiary, executive role, and ownership interest.  Individual stocks and stock options should be disclosed; diversified mutual funds do not need to be disclosed. Research funding from ineligible companies should be disclosed by the principle or named investigator even if that individual&rsquo;s institution receives the research grant and manages the funds.</li>
                <li>Confirm that CRF will publish the submitted disclosure information in related activity materials, in print and/or online and/or in the activity App, as applicable.</li>
                <li>Confirm that all educational materials submitted (slides, handouts, articles, etc.) will contain and be reviewed for the proper disclosure statement(s) and references to the best available evidence.</li>
                <li>Confirm that no commercial support or honoraria will be accepted directly from ineligible companies for participation in an accredited continuing education activity, including individual presentation, publication, or attendance at a program.</li>
                <li>Acknowledge that if reasonable information is obtained by the accredited provider which results in doubt as to the validity of the confirmations above, that participation in the related activity formulation will be revoked.</li>
                <li>Confirm that the presentation/educational materials will contain absolutely NO recommendations or promotion regarding any products or services for which a financial relationship exists. ANY mention of a product or device within the verbal presentation, visuals, or handout material MUST be technique/procedure oriented only; must be based on peer reviewed, best available evidence/independent unbiased scientific research, and will relate to content in a way that conforms to the standards of medical care published within generally accepted literature. </li>
            </ol>
            <p><strong>Note: Must obtain disclosures from all authors on each abstract who is presenting only. The individuals considered in control of content for a presentation on a research study are those who are involved in the planning, reviewing, and delivery of the actual CE presentation. Others who were involved in the research or who were authors of a published research paper would not be considered in control of the CE content if they were not involved in the presentation.</strong></p>
            <p><strong><em><u>Disclose</u></em></strong><strong><em> all financial relationships within the <u>prior 24 months</u></em></strong><strong> (if this information changes before the activity start date, notify CME Committee Chair):</strong></p>




            <!-- GLOBAL -->
            <!--<div ng-show="disclosure_section == 'global'">
                <p>Cardiovascular Research Foundation (CRF) is accredited by the Accreditation Council for Continuing
                    Medical Education (ACCME) to provide continuing medical education for physicians. CRF designates
                    educational activities for <em>AMA PRA Category 1 Credit<sup>TM</sup></em>. As such, CRF has the
                    obligation to ensure delivery of education that is balanced, independent from ineligible companies,
                    free of commercial bias, objective, and scientifically rigorous.</p>
                <p>It is mandatory that all faculty must complete this form, sign and return it. Participants who fail
                    to promptly complete this form and return to CRF, or as directed, will be disqualified from
                    participation in any accredited activities, in accordance with ACCME requirements.</p>
                <p>All the recommendations involving clinical medicine in a accredited continuing education (CE) activity are based on evidence that is
                    accepted within the profession of medicine as adequate justification for their indications and
                    contraindication in the care of patients. All scientific research referred to, reported or used in
                    a accredited CE activity in support or justification of a patient care recommendation must conform to the generally
                    accepted standards of experimental design, data collection and analysis.</p>
                <p>Learners participating in any accredited CE activity sponsored, co-sponsored, or jointly provided by CRF must
                    be able to evaluate the objectivity of the information, analysis, and recommendations presented.
                    Thus, ineligible companies financial relationships of all faculty must be disclosed to
                    learners.</p>
                <p><strong><span class="underline">An ineligible company</span>  is defined by the ACCME as any 
                    entity whose primary business is producing, marketing, re-selling, or distributing health care products used by or on patients.</strong> This does not
                    include providers of clinical services directly to patients. CRF has determined that disclosures must
                    include any or all of the following: receipt of financial support from a commercial interest for
                    research activities or other scientific work, a personal financial or proprietary interest, or a
                    consultant relationship to a company involved in the cardiovascular drug, device, or digital health environment.</p>

                <p><span class="underline">Financial relationships</span> in any amount must be reported if they occurred in the 24-month period preceding the time that an individual
                    assumed a role planning and/or controlling content of a accredited CE activity, thereby causing a conflict of interest even if the
                    relationship has been divested, including those of a spouse or partner. The ACCME considers financial relationships to create actual <span class="underline">conflicts of interest</span> when an individual having a
                    financial relationship with <span class="underline">an ineligible company</span> <strong>has</strong> the opportunity to affect the content of an accredited CE activity about the products or services of that <span class="underline">ineligible company</span>.
                    The ACCME has not set a minimum dollar amount for relationships to be considered relevant and does not use the term significant to describe financial relationships.


                <p>
                    Based on the above, <strong><span class="underline">ALL</span></strong> faculty must:
                </p>
                <ol type="A">
                    <li>Disclose any aspect of his/her personal or professional circumstances that might reasonably be
                        related to the educational activity content or material being presented or disseminated OR
                        indicate that they have nothing to disclose.
                    </li>
                    <li>Confirm that any financial relationship/affiliation disclosed will in no way influence the
                        content of material being presented or disseminated.
                    </li>
                    <li>Confirm that the submitted disclosure information will be included in print on any slides or
                        handout material. CRF will additionally publish the submitted disclosure information in related
                        activity materials, in print and/or online and/or in the activity App, as applicable.
                    </li>
                    <li>Confirm that all educational materials submitted (slides, handouts, articles, etc.) will contain
                        and be reviewed for the proper disclosure statement(s) and references to the best available
                        evidence.
                    </li>
                    <li>Confirm that no commercial support or honoraria will be accepted from outside sources for
                        participation in any accredited CE activity, including individual presentation, publication, or attendance at
                        a program.
                    </li>
                    <li>Acknowledge that if reasonable information is obtained by the accredited provider which results
                        in doubt as to the validity of the confirmations above, that participation in any related
                        activity formulation will be revoked.
                    </li>
                    <li>Confirm that the presentation/educational materials will contain absolutely NO recommendations
                        or promotion regarding any products or services for which a financial relationship exists. ANY
                        mention of a product or device within the verbal presentation, visuals, or handout material MUST
                        be technique/procedure oriented only; must be based on peer reviewed, best available
                        evidence/independent unbiased scientific research, and will relate to content in a way that
                        conforms to the standards of medical care published within generally accepted literature.
                    </li>
                </ol>
            </div>-->
            <!-- FACULTY -->
            <!--<div ng-show="disclosure_section == 'faculty'">
                <p>Cardiovascular Research Foundation (CRF) is accredited by the Accreditation Council for Continuing
                    Medical Education (ACCME) to provide continuing medical education for physicians. CRF designates
                    educational activities for <em>AMA PRA Category 1 Credit<sup>TM</sup></em>. As such, CRF has the
                    obligation to ensure delivery of education that is balanced, independent from ineligible companies,
                    free of commercial bias, objective, and scientifically rigorous.</p>
                <p>It is mandatory that all faculty must complete this form, sign and return it. Participants who fail
                    to promptly complete this form and return to CRF, or as directed, will be disqualified and replaced,
                    in accordance with ACCME requirements.</p>
                <p>All the recommendations involving clinical medicine in any accredited continuing education activity are based on evidence that is
                    accepted within the profession of medicine as adequate justification for their indications and
                    contraindication in the care of patients. All scientific research referred to, reported or used in
                    CME in support or justification of a patient care recommendation must conform to the generally
                    accepted standards of experimental design, data collection and analysis.</p>
                <p>Learners participating in any accredited CE activity sponsored, co-sponsored, or jointly provided by CRF must
                    be able to evaluate the objectivity of the information, analysis, and recommendations presented.
                    Thus, financial relationships with ineligible companies of all faculty must be disclosed to
                    learners.</p>
                <p><strong><span class="underline">An ineligible company</span>  is defined by the ACCME as any 
                    entity whose primary business is producing, marketing, re-selling, or distributing health care products used by or on patients.</strong> This does not
                    include providers of clinical services directly to patients. CRF has determined that disclosures must
                    include any or all of the following: receipt of financial support from a commercial interest for
                    research activities or other scientific work, a personal financial or proprietary interest, or a
                    consultant relationship to a company involved in the cardiovascular drug, device, or digital health environment.</p>

                <p><span class="underline">Financial relationships</span> in any amount must be reported if they occurred in the 24-month period preceding the time that an individual
                    assumed a role planning and/or controlling content of a accredited CE activity, thereby causing a conflict of interest even if the
                    relationship has been divested, including those of a spouse or partner. The ACCME considers financial relationships to create actual <span class="underline">conflicts of interest</span> when an individual having a
                    financial relationship with <span class="underline">an ineligible company</span> <strong>has</strong> the opportunity to affect the content of an accredited CE activity about the products or services of that <span class="underline">ineligible company</span>.
                    The ACCME has not set a minimum dollar amount for relationships to be considered relevant and does not use the term significant to describe financial relationships.

                <p>
                    Based on the above, <strong><span class="underline">ALL</span></strong> faculty must:
                </p>
                <ol type="A">
                    <li>Disclose any aspect of his/her personal or professional circumstances that might reasonably be
                        related to the educational activity content or material being presented or disseminated OR
                        indicate that they have nothing to disclose.
                    </li>
                    <li>Confirm that any financial relationship/affiliation disclosed will in no way influence the
                        content of material being presented or disseminated.
                    </li>
                    <li>Confirm that the submitted disclosure information must, and will, be stated verbally from the
                        podium prior to presentation.
                    </li>
                    <li>Confirm that the submitted disclosure information will be included in print on any slides or
                        handout material. CRF will additionally publish the submitted disclosure information in related
                        activity materials, in print and/or online and/or in the activity App, as applicable.
                    </li>
                    <li>Confirm that all educational materials submitted (slides, handouts, articles, etc.) will contain
                        and be reviewed for the proper disclosure statement(s) and references to the best available
                        evidence.
                    </li>
                    <li>Confirm that no commercial support or honoraria will be accepted from outside sources for
                        participation in the accredited CE activity, including individual presentation, publication, or attendance at
                        a program.
                    </li>
                    <li>Acknowledge that if reasonable information is obtained by the accredited provider which results
                        in doubt as to the validity of the confirmations above, that participation in the related
                        activity formulation will be revoked.
                    </li>
                    <li>Confirm that the presentation/educational materials will contain absolutely NO recommendations
                        or promotion regarding any products or services for which a financial relationship exists. ANY
                        mention of a product or device within the verbal presentation, visuals, or handout material MUST
                        be technique/procedure oriented only; must be based on peer reviewed, best available
                        evidence/independent unbiased scientific research, and will relate to content in a way that
                        conforms to the standards of medical care published within generally accepted literature.
                    </li>
                </ol>
            </div>-->
            <!-- PLANNER -->
            <!--<div ng-show="disclosure_section == 'planner_faculty'">
                <p>Cardiovascular Research Foundation (CRF) is accredited by the Accreditation Council for Continuing
                    Medical Education (ACCME) to provide continuing medical education for physicians. CRF designates
                    educational activities for <em>AMA PRA Category 1 Credit<sup>TM</sup></em>. As such, CRF has the
                    obligation to ensure delivery of education that is balanced, independent from ineligible companies,
                    free of commercial bias, objective, and scientifically rigorous.</p>
                <p>Learners participating in any accredited continuing education (CE) activity sponsored, co-sponsored, or jointly provided by CRF must
                    be able to evaluate the objectivity of the information, analysis, and recommendations presented.
                    Thus, financial relationships with ineligible companies of all content planners must be
                    <strong>disclosed to learners</strong>.</p>
                <p><strong><span class="underline">An ineligible company</span>  is defined by the ACCME as any 
                    entity whose primary business is producing, marketing, re-selling, or distributing health care products used by or on patients.</strong> This does not
                    include providers of clinical services directly to patients. CRF has determined that disclosures must
                    include any or all of the following: receipt of financial support from a commercial interest for
                    research activities or other scientific work, a personal financial or proprietary interest, or a
                    consultant relationship to a company involved in the cardiovascular drug, device, or digital health environment.</p>

                <p><span class="underline">Relevant financial relationships</span> in any amount must be reported if
                    they occurred in the 24-month period preceding the time that an individual assumed a role
                    planning and/or controlling content of a accredited CE activity, thereby causing a conflict of interest even
                    if the relationship has been divested, including those of a spouse or partner. The ACCME considers
                    financial relationships to create actual <span class="underline">conflicts of interest</span> when
                    an individual having a financial relationship with a commercial interest <strong>has</strong> the
                    opportunity to affect the content of an accredited CE activity  about the products or services of that commercial interest.
                    The ACCME has not set a minimum dollar amount for relationships to be considered relevant and does
                    not use the term significant to describe financial relationships.</p>

                <p>All persons involved with content development, including activity chairs/co-chairs,
                    directors/co-directors, planning committee members, reviewers, presenters, moderators, authors,
                    editors, staff, and others as applicable, must:</p>

                <ol type="A">
                    <li>Disclose any aspect of his/her personal or professional circumstances which might reasonably be
                        related to the educational activity content or material being presented or disseminated
                    <li>Confirm that any financial relationship/affiliation disclosed will in no way influence content
                        development.
                    <li>Confirm that the submitted disclosure information may be included in print on any slides or in
                        any handout material and may be additionally published in related activity materials, in print
                        and/or online, as applicable.
                    <li>Confirm that no commercial support or honoraria will be accepted from outside sources for
                        participation in the accredited CE activity  activity content formulation, approval, individual presentation,
                        publication, or attendance at a program.
                    <li>Confirm that the activity content will not be formulated or approved so as to include
                        recommendations or promotional language regarding any products or services for which a financial
                        relationship exists.
                    <li>Confirm that necessary steps will be taken to ensure that all accredited CE activity participants
                        including faculty abide by the guidelines outlined in their respective Disclosure/Acceptance of
                        Responsibility form, including but not limited to presentation, handout and audio-visual
                        content, verbal and written disclosures, identification and resolution of conflict of interest,
                        and honoraria policies
                    <li>Acknowledge that if reasonable information results in doubt as to the validity of the
                        confirmations above, that participation in the related activity formulation will be revoked; a
                        replacement may be assigned and/or the material substituted, or other action taken, as
                        appropriate.
                </ol>
            </div>--->

            <!--<div ng-show="disclosure_section == 'planner_faculty'">
                <p><strong>CONFLICT OF INTEREST DISCLOSURE</strong></p>
                <p>
                    It is mandatory that all persons engaged in content planning, development, presentation, review, or
                    management must complete this form, sign and return it. Participants who fail to promptly complete
                    this form and return to CRF, or as directed, will be disqualified and replaced, in accordance with
                    ACCME requirements.
                </p>

                <p><strong>Acceptance of Responsibility</strong></p>
                <ul>
                    <li>I confirm that I have disclosed all relevant financial relationships or conflicts-of-interest
                        <span class="underline">OR I have indicated that I have nothing to disclose</span>.
                    </li>
                    <li>I confirm that any financial relationship/affiliation disclosed will in no way influence content
                        development for any accredited CE activity that is directly related to that financial relationship,
                        including choosing topics and faculty for the accredited CE activity, and that my decisions will be
                        subject to review by an individual who has no conflict of interest and who has sufficient
                        expertise to plan the activity. Otherwise, I agree to either divest myself of these financial
                        relationship(s) or I agree to recuse myself from the relevant planning activity that is related
                        to these relationship(s).
                    </li>
                    <li>I acknowledge that the above submitted disclosure information must, and will, be presented
                        and/or published in related activity materials, in print and/or online, as required.
                    </li>
                    <li><strong>I confirm that the activity content over which I have responsibility as well as my
                        financial relationships will be reviewed by individuals</strong> who have no conflict of
                        interest and who have sufficient expertise to plan the activity, and I agree to abide by any
                        recommendations of these reviewers.
                    </li>
                    <li><strong>I confirm that I will accept no commercial support in any form, or honorarium, from
                        outside sources for participation in this accredited CE activity.</strong></li>
                    <li>I confirm that I will work to ensure that this activity is in compliance with all accredited CE policies,
                        standards, and guidelines.
                    </li>
                    <li><strong>I agree to comply with the requirements to protect health information under the Health
                        Insurance Portability & Accountability Act of 1996. (HIPAA)</strong></li>
                </ul>
            </div>-->

            <!--<div ng-show="disclosure_section == 'faculty'">
                <p><strong>Acceptance of Responsibility</strong></p>
                <ul>
                    <li><strong>Within my role(s) in this activity, I will ensure balance, independence, objectivity,
                        and scientific rigor</strong></li>
                    <li>I confirm that I have disclosed all circumstances relevant to the educational activity content
                        or material being presented/disseminated <span class="underline">OR</span> indicated that I have
                        nothing to disclose.
                    </li>
                    <li>I acknowledge that CRF is not responsible for the content of my presentation/material and that
                        it may curtail or terminate my presentation/material in the event it contains content which is
                        determined to be in violation of the foregoing representation.
                    </li>
                    <li>I will provide presentation/handout/materials prior to the activity, as appropriate, for CE
                        review (content validation, peer-review for resolution of conflict of interest, or other CE
                        purposes), if requested.
                    </li>
                    <li>I confirm that any financial relationship/affiliation disclosed will in no way influence the
                        content of material being presented or disseminated.
                    </li>
                    <li>I acknowledge that the above submitted disclosure information must, and will, be stated verbally
                        from the podium prior to the activity/presentation and additionally published in related
                        activity materials, in print and/or online, as required.
                    </li>
                    <li><strong>I confirm that the information presented will contain no recommendations or promotion
                        regarding any products or services for which a personal financial relationship exists.</strong>
                        ANY mention of a product or device within the verbal presentation, visuals, handout, or
                        published materials must be technique/procedure oriented only; must be based on peer reviewed,
                        best available evidence/independent unbiased scientific research, and will relate to content in
                        a way that conforms to the standards of medical care published within generally accepted
                        literature.
                    </li>
                    <li>I confirm that all educational materials submitted (slides, handouts, articles, etc.) will
                        contain, and will be reviewed for, the proper disclosure statement(s) and references to the best
                        available evidence.
                    </li>
                    <li>I confirm that I will accept no commercial support in any form, or honorarium, from outside
                        sources for participation in any accredited CE activity content formulation, approval, individual
                        presentation, publication, or attendance at a program.
                    </li>
                    <li>I acknowledge that if reasonable information is obtained by the accredited provider which
                        results in doubt as to the validity of the confirmations above, that participation in the
                        related activity formulation will be revoked; a replacement may be assigned and/or the material
                        substituted, or other action taken, as appropriate.
                    </li>
                    <li>I represent that my presentation/material will contain no sexually suggestive content nor
                        content which contains inappropriate references to race, color, religion, sex, or national
                        origin, and I accept full responsibility for the presentation of appropriate and ethical
                        material.
                    </li>
                    <li><strong>I agree to comply with the requirements to protect health information under the Health
                        Insurance Portability & Accountability Act of 1996. (HIPAA)</strong></li>
                    <li>I certify that all photographic material presented is done so with the appropriate
                        medical/patient releases for photography, and subsequent use in presentations.
                    </li>
                    <li><strong>I will inform learners when I discuss or reference unapproved or unlabeled uses of
                        therapeutic agents or products</strong></li>
                </ul>
            </div>-->

        </div>
    </div>

    <div class="m-b text-right">
        <span ng-class="{'text-muted': policy_read}">I have read and accept the policy <input type="checkbox"
                                                                                              ng-model="policy_read"
                                                                                              ng-disabled="policy_read"></span>
    </div>

    <div class="panel panel-default">
        <div class="panel-heading">
            <span class="h4">Disclosure of Financial Relationships</span>
        </div>
        <div class="panel-body">
            <!--<div ng-show="disclosure_section == 'faculty' || disclosure_section == 'global'">
                <p>It is mandatory that all faculty must complete this form. Participants who fail to promptly complete
                    this form will be disqualified and replaced, in accordance with ACCME requirements.</p>
                <div class="line line-dashed b-b line-lg pull-in"></div>
            </div>--->
            <p><strong>Completed by Planner, Faculty, or Others Who May Control Educational Content.</strong></p>

            Please disclose <strong>all financial relationships</strong> that you have had in the past 24 months with ineligible companies
            (see definition below). For each financial relationship, enter the name of the ineligible company and the
            nature of the financial relationship(s). There is no minimum financial threshold; we ask that you disclose all financial relationships, regardless of the amount, with ineligible companies.
            You should disclose all financial relationships regardless of the potential relevance of each relationship to the education.

            <div class="line line-dashed b-b line-lg pull-in"></div>

            <!--<p class="font-bold">
                <span class="underline">Check</span> and complete all that apply within the <span
                    class="font-bold underline">last 24 months</span>
                <span ng-show="disclosure_section == 'faculty' || disclosure_section == 'planner_faculty'">
                    (if this information changes before the activity start date, notify activity planners immediately):
                </span>
            </p>---->

            <ng-form name="disclosure_form" class="form-horizontal form-validation">

                <table class="table borderless">
                    <tr>
                        <td>
                            <input type="checkbox" ng-model="disclosure_model.conflict_none"
                        ></td>
                        <td>
                            I have <strong class="underline">no</strong> financial relationship(s) to disclose.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="font-bold text-md text-center">OR</td>
                    </tr>
                    <tr>
                        <td>
                            <input type="checkbox" ng-model="disclosure_model.conflict_exists_1">
                        </td>
                        <td>
                            Within the prior 24 months, I have had a financial relationship with a company producing, marketing, selling, re-selling, or distributing healthcare products used by or on patients.
                        </td>
                    </tr>

                </table>

                <div ng-show="disclosure_model.conflict_exists_1">

                    <table class="table table-bordered b-t b-light m-b-sm">
                        <thead>
                        <tr>
                            <th>Name of Ineligible Company</th>
                            <th>Nature of Financial Relationship</th>
                            <!--<th>Has the Relationship Ended?</th>-->
                            <!--<th colspan="2">Type of Relationship</th>--->
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                An <strong>ineligible company</strong> is any entity whose primary business is producing, marketing, selling, re-selling, or distribution healthcare products used by or on patients.
                            </td>
                            <td>
                                Examples of financial relationships include employee, researcher, consultant, advisor, speaker, independent contractor (including contracted research), royalties or patent beneficiary,
                                executive role, and ownership interest. Individual stocks and stock options should be disclosed; diversified mutual funds do not need to be disclosed. Research funding from ineligible
                                companies should be disclosed by the principal or named investigator even if that individual's institution receives the research grant and manages the funds.
                            </td>
                            <!--<td>
                                If the financial relationship existed during the last 24 months, but had now ended, please check the box in this column. This will help the education staff determine if any mitigation steps need to be taken.
                            </td>-->
                        </tr>
                        <tr ng-repeat="conflict in conflicts track by $index">
                            <td width="30%">
                                <ui-select
                                        ng-model="conflicts_model[conflict.id].company"
                                        ng-init="conflicts_model[conflict.id].company = {name: conflict.company_name}"
                                        theme="bootstrap"
                                        required
                                >
                                    <ui-select-match
                                            placeholder="Select or search the company">
                                        {{$select.selected.name}}
                                    </ui-select-match>
                                    <ui-select-choices
                                            repeat="item in companies | filter: $select.search">
                                        <div ng-bind-html="item.name | highlight: $select.search"></div>
                                    </ui-select-choices>
                                </ui-select>

                                <div ng-show="conflicts_model[conflict.id].relationship.relationship_title != '' && conflicts_model[conflict.id].company.name == ''">
                                    <small class="text-danger"><i>Company is required</i></small>
                                </div>

                                <!-- COMPANY OTHER -->
                                <input ng-if="conflicts_model[conflict.id].company.name == 'Other'"
                                       ng-init="conflicts_model[conflict.id].company_other = conflict.company_name_other"
                                       ng-model="conflicts_model[conflict.id].company_other"
                                       type="text"
                                       class="m-t-xs form-control "
                                       placeholder="Please Specify"
                                       ng-required="true"
                                />
                                <div ng-show="conflicts_model[conflict.id].company.name == 'Other' && !conflicts_model[conflict.id].company_other">
                                    <small class="text-danger"><i>Company name is required</i></small>
                                </div>

                            </td>
                            <td width="30%">
                                <ui-select
                                        ng-model="conflicts_model[conflict.id].relationship"
                                        ng-init="conflicts_model[conflict.id].relationship = {relationship_title: conflict.relationship_title}"
                                        theme="bootstrap"
                                        required
                                >
                                    <ui-select-match
                                            placeholder="Select or search the nature of the relationship">
                                        {{$select.selected.relationship_title}}
                                    </ui-select-match>
                                    <ui-select-choices
                                            repeat="item in relationships | filter: $select.search">
                                        <div ng-bind-html="item.relationship_title | highlight: $select.search"></div>
                                    </ui-select-choices>
                                </ui-select>


                                <!-- OTHER -->
                                <input ng-show="conflicts_model[conflict.id].relationship.relationship_title == 'Other'"
                                       ng-init="conflicts_model[conflict.id].relationship_other = conflict.relationship_title_other"
                                       ng-model="conflicts_model[conflict.id].relationship_other"
                                       type="text"
                                       class="m-t-xs form-control "
                                       placeholder="Please Specify"
                                       ng-required="true"
                                />
                                <div ng-show="conflicts_model[conflict.id].relationship.relationship_title == 'Other' && !conflicts_model[conflict.id].relationship_other">
                                    <small class="text-danger"><i>Nature of the relationship is required</i></small>
                                </div>

                                <!-- COMPANY POSITION TITLE -->
                                <input ng-if="conflicts_model[conflict.id].relationship.relationship_title == 'Company Position Title (i.e. Chief Medical Officer, Director, etc)'"
                                       ng-init="conflicts_model[conflict.id].position_title = conflict.position_title"
                                       ng-model="conflicts_model[conflict.id].position_title"
                                       type="text"
                                       class="m-t-xs form-control "
                                       placeholder="Provide Specific Title"
                                       ng-required="true"
                                />
                                <div ng-show="conflicts_model[conflict.id].relationship.relationship_title == 'Company Position Title (i.e. Chief Medical Officer, Director, etc)' && !conflicts_model[conflict.id].position_title">
                                    <small class="text-danger"><i>Company position title is required</i></small>
                                </div>

                            </td>
                            <!--<td width="30%">
                                <input type="checkbox" ng-model="conflicts_model[conflict.id].relationship_ended">
                            </td>--->
                            <!--<td width="30%">
                                <ui-select
                                        ng-model="conflicts_model[conflict.id].relationship_type"
                                        ng-init="conflicts_model[conflict.id].relationship_type = {relationship_type_title: conflict.relationship_type_title}"
                                        theme="bootstrap"
                                        required
                                >
                                    <ui-select-match
                                            placeholder="Select Type of Relationship">
                                        {{$select.selected.relationship_type_title}}
                                    </ui-select-match>
                                    <ui-select-choices
                                            repeat="item in relationship_types | filter: $select.search">
                                        <div ng-bind-html="item.relationship_type_title | highlight: $select.search"></div>
                                    </ui-select-choices>
                                </ui-select>

                                <div ng-show="conflicts_model[conflict.id].relationship.relationship_title != '' && !conflicts_model[conflict.id].relationship_type.relationship_type_title">
                                    <small class="text-danger"><i>Type of Relationship is required</i></small>
                                </div>
                            </td>--->

                            <td width="10%">
                                <button type="button" class="btn btn-xs btn-default m-t-xs"
                                        alt="Remove"
                                        ng-click="removeConflict(conflict)"><i
                                        class="fa fa-times fa-fw"></i>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div>
                        <button class="btn btn-xs font-bold btn-success m-b-sm"
                                ng-click="addConflict()">
                            <i class="fa fa-plus text-white"></i> Add More
                        </button>
                    </div>
                </div>


                <div class="line line-dashed b-b line-lg pull-in"></div>

                <!-- FACULTY -->
                <div ng-show="disclosure_section == 'faculty'">
                    <h4>Unlabeled Use of Drugs or Devices</h4>

                    <div>
                        <label class="col-sm-8 col-lg-8 col-md-8 control-label">
                            <strong>Off-label/unapproved uses are included in my presentation(s) / material(s).</strong>
                        </label>
                        <div class="col-sm-4 col-lg-4 col-md-4">
                            <div class="radio">
                                <label>
                                    <input type="radio"
                                           name="field_off_label"
                                           ng-model="disclosure_model.off_label"
                                           ng-value="'yes'"
                                           required
                                    > Yes &nbsp;&nbsp;&nbsp;
                                </label>
                                <label>
                                    <input type="radio"
                                           name="field_off_label"
                                           ng-model="disclosure_model.off_label"
                                           ng-value="'no'"
                                           required
                                    > No
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="line line-dashed b-b line-lg pull-in"
                         ng-show="disclosure_model.offlabel_uses_included == 'yes'"></div>

                    <div ng-show="disclosure_model.off_label == 'yes'">
                        <label class="col-sm-8 col-lg-8 col-md-8 control-label">
                            List the drug/device (no trade names) and to what purpose they will be presented:
                        </label>
                        <div class="col-sm-4 col-lg-4 col-md-4">
                            <input type="text"
                                   class="form-control"
                                   ng-model="disclosure_model.off_devices"
                                   required
                                   name="field_drug_device_purpose"
                            />

                        </div>
                    </div>

                    <div class="line line-dashed b-b line-lg pull-in"></div>

                    <p class="font-bold well">Actions to be taken for final resolution of conflict of interest (COI)
                        will be approved or directed by the CME Committee/Program Chairs, etc. responsible for oversight
                        of the CME activity. Alternate suggestions for resolving specific conflicts of interest may be
                        discussed with the appropriate persons responsible for planning the activity.</p>
                    <div class="line line-dashed b-b line-lg pull-in"></div>

                </div>

                <div>
                    <label class="col-sm-1 col-lg-1 col-md-1 control-label">
                        <input type="checkbox"
                               ng-model="disclosure_model.confirm_1"
                               name="field_confirm_1"
                               ng-true-value="'yes'"
                               required
                        >
                    </label>
                    <div class="col-sm-10 col-lg-10 col-md-10">
                        <span ng-show="disclosure_section == 'faculty'">I acknowledge that identified Conflict(s) of Interest (COI) must be mitigated prior to confirming participation in this activity and agree to comply with the mitigation proposed by the activity planners to manage my stated conflict(s) of interest.</span>
                        <span ng-show="disclosure_section == 'planner_faculty'">I acknowledge that identified Conflict(s) of Interest (COI) must be mitigated prior to confirming participation in planning of this activity and I agree to comply with the mitigation proposed by the activity reviewers to manage my stated conflict(s) of interest.</span>
                        <span ng-show="disclosure_section == 'global'">I acknowledge that identified Conflict(s) of Interest (COI) must be mitigated prior to confirming participation in any activity and agree to comply with the mitigation proposed by the activity reviewers to manage my stated conflict(s) of interest.</span>
                    </div>
                </div>

                <div class="line line-dashed b-b line-lg pull-in"></div>

                <div>
                    <label class="col-sm-1 col-lg-1 col-md-1 control-label">
                        <input type="checkbox"
                               ng-model="disclosure_model.confirm_2"
                               name="field_confirm_1"
                               ng-true-value="'yes'"
                               required
                        >
                    </label>
                    <div class="col-sm-10 col-lg-10 col-md-10 font-bold">I attest that I have read and reflected upon the Conflict
                        of Interest Policy and accept responsibility as outlined above. The information I submit is
                        accurate.
                    </div>
                </div>

            </ng-form>

        </div>
    </div>

    <div class="m-t-xl text-danger" ng-show="error_msg">
        <span class="font-bold">Please fill-out the following required fields:</span>
        <div class="line line-dashed b-b b-danger"></div>
        <span class="font-bold" ng-bind-html="error_msg | unsafe"></span>
        <div class="line line-dashed b-b b-danger"></div>
    </div>

    <div>
        <div class="m-t-lg text-center">
            <button ng-click="saveTask()" type="button" class="btn btn-success">Save <span ng-show="task.status == '0'">and Continue</span>
            </button>
            <button ng-hide="disclosure_section == 'global'" ng-click="cancelTask()" type="button"
                    class="btn btn-danger m-l-sm ">Cancel
            </button>
        </div>
    </div>

</div>