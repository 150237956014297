<div ng-show="faculty_disclosure.conflict_none == 'yes'">
    I have <strong class="underline">no</strong> financial relationship(s) to disclose.
    <div class="line line-dashed b-b line-lg"></div>
</div>
<div ng-show="faculty_disclosure.conflict_exists_1 == 'yes'" class="m-b-sm">
    Within the prior 24 months, I have had a financial relationship with a company producing, marketing, selling, re-selling, or distributing healthcare products used by or on patients.
</div>

<div ng-show="!faculty_disclosure.conflict_none && !faculty_disclosure.conflict_exists_1 && !faculty_disclosure.conflict_exists_2">
    <span class="text-danger font-bold">No Conflict of Interest Disclosure Submitted.</span>
    <div class="line line-dashed b-b line-lg"></div>
</div>

<div ng-show="faculty_disclosure.conflict_exists_1 == 'yes' || faculty_disclosure.conflict_exists_2 == 'yes'">

    <table class="table" ng-if="faculty_disclosure.conflicts.length">
        <tr>
            <th>Nature of Financial Relationship</th>
            <th>Name of Ineligible Company</th>
        </tr>
        <tr ng-repeat="conflict in faculty_disclosure.conflicts">
            <td>
                <span ng-hide="conflict.relationship_title == 'Other'">{{conflict.relationship_title}}</span>
                <span>{{conflict.relationship_title_other}} <br>{{conflict.position_title}}</span>
            </td>
            <td>
                <span ng-hide="conflict.company_name == 'Other'">{{conflict.company_name}}</span> {{conflict.company_name_other}}
            </td>
        </tr>
    </table>

    <div ng-if="!faculty_disclosure.conflicts.length">
        <span class="text-danger font-bold">Indicated a Conflict of Interest Relationship and/or Commercial Interest. No further details provided.</span>
    </div>

    <div class="line line-dashed b-b line-lg"></div>
</div>


<div>
    <div class="form-group">
        <label class="col-sm-8 col-lg-8 col-md-8 control-label">
            <span>Presenters/Authors: Off-label/unapproved uses are included in my presentation(s) / material(s):</span>
        </label>
        <div class="col-sm-4 col-lg-4 col-md-4">
            <span ng-show="faculty_disclosure.off_label == 'no'" class="font-bold text-success">No</span>
            <span ng-show="faculty_disclosure.off_label == 'yes'" class="font-bold text-success">Yes</span>

            <span ng-show="!faculty_disclosure.off_label" class="text-danger font-bold">No answer provided yet.</span>

        </div>
    </div>
    <div class="line line-dashed b-b line-lg"></div>

    <div class="form-group" ng-show="faculty_disclosure.off_label == 'yes'">
        <label class="col-sm-8 col-lg-8 col-md-8 control-label">
            <span>List the drug/device (no trade names) and to what purpose you will be presenting it:</span>
        </label>
        <div class="col-sm-4 col-lg-4 col-md-4">
            <span class="font-bold">{{faculty_disclosure.off_devices}}</span>
            <span ng-show="!faculty_disclosure.off_devices" class="text-danger font-bold">No answer provided yet.</span>
        </div>
    </div>
    <div class="line line-dashed b-b line-lg" ng-show="faculty_disclosure.off_label == 'yes'"></div>

    <div class="form-group">
        <label class="col-sm-8 col-lg-8 col-md-8 control-label">
            <span>I acknowledge that identified Conflict(s) of Interest (COI) must be resolved prior to confirming participation in this activity and agree to comply with the resolutions proposed by the activity planners to manage my stated conflict(s) of</span>
        </label>
        <div class="col-sm-4 col-lg-4 col-md-4">
            <div ng-show="!faculty_disclosure.confirm_1" class="text-danger font-bold">No answer provided yet.</div>
            <div ng-show="faculty_disclosure.confirm_1 == 'yes'" class="text-success font-bold">Yes</div>
        </div>
    </div>
    <div class="line line-dashed b-b line-lg"></div>

    <div class="form-group">
        <label class="col-sm-8 col-lg-8 col-md-8 control-label">
            <span>I attest that I have read and reflected upon the Conflict of Interest Policy and accept responsibility as outlined above. The information I submit is accurate.</span>
        </label>
        <div class="col-sm-4 col-lg-4 col-md-4">
            <div ng-show="!faculty_disclosure.confirm_2" class="text-danger font-bold">No answer provided yet.</div>
            <div ng-show="faculty_disclosure.confirm_2 == 'yes'" class="text-success font-bold">Yes</div>
        </div>
    </div>
    <div class="line line-dashed b-b line-lg"></div>

</div>