<div ng-show="conference.banner_url">
    <style>
        .picture-main {
            background: url("{{conference.banner_url}}") no-repeat;
            padding: 0; margin: 0;
            width: 100%;
            height: 158px;
        }
        .cover{
            background-size: cover;
        }
    </style>
    <div class="picture-main cover"></div>
</div>