<div data-ng-include="'blocks/conference_logo.html'"></div>

<div class="wrapper-lg">
    <div class="padder-lg">

        <div data-ng-include="'blocks/conference_nav.html'"></div>

        <h5>{{conference.program_coordinator.first_name}} {{conference.program_coordinator.last_name}} (<a href="mailto:{{conference.program_coordinator.email}}">{{conference.program_coordinator.email}}</a>)</h5>

    </div>
</div>